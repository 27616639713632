import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Stats() {
    return (
        <Container>
            <Row>
                <Col>Median: 145.1mi</Col>
                <Col>Mode: 1.5mi</Col>
                <Col>Mean: 477.3mi</Col>
            </Row>
            <Row>
                <Col>Furthest: 9754.8mi</Col>
                <Col>Closest: 0.03mi</Col>
            </Row>
            <Row>
                <Col>Total: 127913 miles of mailed cards</Col>
            </Row>
        </Container>
    );
}

export default Stats;
