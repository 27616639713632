import logo from './logo.svg';
import './App.css';
import MapChart from "./MapChart";
import Stats from "./Stats";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <Stats />
        <MapChart />
      </header>
    </div>
  );
}

export default App;
